export default [

  {
    path: '/main-page',
    name: 'main',
    component: () => import('@/views/dashboard/main/cards.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },

 
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/statistic/mainStatistic.vue'),
    meta: {
      pageTitle: 'Statistic',
      breadcrumb: [
        {
          text: 'Dashboard',
        },
        {
          text: 'Analytics ',
          active: true,
        },
      ],
    },
  },

  
  {
    path: '/Users/',
    name: 'users',
    component: () => import('@/views/users/users-list/UsersList.vue'),
  },

  {
    path: '/Users-Add',
    name: 'user-add',
    component: () => import('@/views/users/user-create/UserAdd.vue'),
  },
  {
    path: '/users/:id',
    name: 'userDetails',
    component: () => import('@/views/users/users-details/UserDetails.vue'),
  },

  {
    path: '/Customers/',
    name: 'customers',
    component: () => import('@/views/customers/customer-list/customerList.vue'),
  },
  {
    path: '/Customers-Add',
    name: 'customer-add',
    component: () => import('@/views/customers/customerAdd.vue'),
  },
  {
    path: '/customers/:id',
    name: 'customersDetails',
    component: () => import('@/views/customers/customerDetails.vue'),
  },

  {
    path: '/Compounds-List',
    name: 'compound-list',
    component: () => import('@/views/compounds/list.vue'),
    meta: {
      pageTitle: 'Compounds',
      breadcrumb: [
        {
          text: 'Compound list',
        },
        {
          text: 'All Compounds',
          active: true,
        },
      ],
    },
  },
  {
    path: '/Compound-Types',
    name: 'compound-types',
    component: () => import('@/views/compounds/types.vue'),
    meta: {
      pageTitle: 'Compounds',
      breadcrumb: [
        {
          text: 'Compound Types',
        },
        {
          text: 'All Compound Types',
          active: true,
        },
      ],
    },
  },
  {
    path: '/Ads',
    name: 'ads',
    component: () => import('@/views/ads/adsList.vue'),
  },

  {
    path: '/Category',
    name: 'category',
    component: () => import('@/views/categores/list.vue'),
  },

  {
    path: '/Invoice-Types',
    name: 'Invoice-Types',
    component: () => import('@/views/invoices/types.vue'),
  },

  {
    path: '/Invoices-List/:id',
    name: 'Invoice-list',
    component: () => import('@/views/invoices/list.vue'),
  },

  {
    path: '/Unit-List/compounds/:id/units',
    name: 'unit-list',
    component: () => import('@/views/unites/list.vue'),
  },

  {
    path: '/Unit-Block/:id',
    name: 'unit-block',
    component: () => import('@/views/unites/blocks.vue'),
  },

  {
    path: '/Services/:id',
    name: 'Services',
    component: () => import('@/views/services/list.vue'),
  },

  {
    path: '/Tickets-List/:id',
    name: 'Tickets-list',
    component: () => import('@/views/tickets/list.vue'),
  },

  {
    path: '/Tickets-Add/:id',
    name: 'Tickets-add',
    component: () => import('@/views/tickets/ticketAdd.vue'),
  },

  {
    path: '/Tickets-Details',
    name: 'Tickets-details',
    component: () => import('@/views/tickets/ticketDetails.vue'),
  },

  {
    path: '/Vehicles',
    name: 'vehicles',
    component: () => import('@/views/vehicles/list.vue'),
  },

  {
    path: '/Trips',
    name: 'trips',
    component: () => import('@/views/trips/list.vue'),
  },

  {
    path: '/Trips-Add',
    name: 'trips-add',
    component: () => import('@/views/trips/add.vue'),
  },

  {
    path: '/Guests/:id',
    name: 'guests',
    component: () => import('@/views/guests/list.vue'),
  },

  {
    path: '/Visits/:id',
    name: 'Visits-list',
    component: () => import('@/views/visits/list.vue'),
  },



  {
    path: '/Installments/:id',
    name: 'Installments-list',
    component: () => import('@/views/installments/installmentsList.vue'),
  },

  {
    path: '/Receipts/:id',
    name: 'Receipts-list',
    component: () => import('@/views/installments/receiptsList.vue'),
  },

  {
    path: '/Stores/:id',
    name: 'stores',
    component: () => import('@/views/stores/storeList.vue'),
  },

  {
    path: '/Sections/',
    name: 'sections',
    component: () => import('@/views/stores/sectionList.vue'),
  },
  {
    path: '/Account',
    name: 'pages-profile',
    component: () => import('@/views/users/account-setting/AccountSetting.vue'),
  },

  {
    path: '/icons',
    name: 'ui-feather',
    component: () => import('@/views/extensions/icons/Feather.vue'),
  },

  {
    path: '/access-control',
    name: 'access-control',
    component: () => import('@/views/extensions/acl/AccessControl.vue'),
    meta: {
      resource: 'ACL',
      action: 'read',
    },
  },

  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/authentication/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },

  {
    path: '/pages/miscellaneous/coming-soon',
    name: 'misc-coming-soon',
    component: () => import('@/views/pages/miscellaneous/ComingSoon.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/pages/miscellaneous/not-authorized',
    name: 'misc-not-authorized',
    component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
    },
  },
  {
    path: '/pages/miscellaneous/under-maintenance',
    name: 'misc-under-maintenance',
    component: () => import('@/views/pages/miscellaneous/UnderMaintenance.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/pages/miscellaneous/error',
    name: 'misc-error',
    component: () => import('@/views/pages/miscellaneous/Error.vue'),
    meta: {
      layout: 'full',
    },
  },

]
