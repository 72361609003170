import Vue from 'vue'
import axios from 'axios'

const TokenType = 'Bearer'
const instance = axios.create({
  baseURL: 'https://comssapi.aswar.solutions/api/v1/',
})

instance.interceptors.request.use(
  config => {
    const accessToekn = localStorage.getItem('access_token')
    if (accessToekn !== null) {
      config.headers.Authorization = `${TokenType} ${accessToekn}`
      config.headers.Accept = "application/json";
    }
    return config
  },
)

// Add a response interceptor
// instance.interceptors.response.use(response => response, error => {
//   if (error.response && error.response.status) {
//     localStorage.removeItem('access_token')
//   }
//   return Promise.reject(error)
// })

// this add just for display json data
Vue.prototype.$http = instance

export default instance
