import { AbilityBuilder, Ability } from '@casl/ability'
import ability from './ability'

export default function defineAbilityFor(user) {
  const { can, rules } = new AbilityBuilder()
  if (user && user.is_superadmin !== undefined && user.permissions !== undefined) {
    if (user.is_superadmin) {
      can('manage', 'all')
    } else {
      can(user.permissions)
    }
  }

  return ability.update(rules)
}