import Vue from 'vue'
import { ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI, { onMounted } from '@vue/composition-api'
import { mapGetters, mapActions, mapMutations } from 'vuex'
import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'
// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'

// Axios Mock Adapter
import '@/@fake-db/db'

// BSV Plugin Registration
// Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

const mixin = {
  data() {
    return {
      baseUrl: '',
    }
  },
  computed: {
    ...mapGetters(['all_data', 'createResponse']),
    getUserInfo() {
      return JSON.parse(localStorage.getItem('userData')) || false
    },
  },
  methods: {
    ...mapMutations([]),
    ...mapActions([
      'getList', 'clearCache', 'create', 'update', 'delete',
    ]),
    can(key) {
      store.getters['authorize/GET_PERMISSONS'].includes(key)
    },
  },
}

Vue.mixin(mixin)
new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
  setup() {
    // onMounted(() => {
    //   if (store.getters['auth/access_token']) {
    //     store.dispatch('authorize/getRoles')
    //     store.dispatch('authorize/getPermissions')
    //   }
    // })
  },
}).$mount('#app')
