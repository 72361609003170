import axios from '@axios'


export default {
  namespaced: true,
  state: {
    data: [],
    meta: {}
  },
  mutations: {
    SET_DATA(state, data) {
      state.data = data.data
      state.meta = data.meta
    },
    PUSH_DATA(state, data) {
      state.data.push(data)
    },
    UPDATE_DATA(state, data) {
      state.data = state.data.map(it => (it.id != id ? it : data))
    },
    DELETE_DATA(state, id) {
      state.data = state.data.filter(it => it.id != id)
    },
  },
  getters: {
    GET_DATA(state) {
      return state.data
    },
    GET_META(state) {
      return state.meta
    },
  },
  actions: {
    async all({ commit }, { path, page, limit }) {
      return await axios
      .get(`${path}?page=${page ?? 1}&limit=${limit ?? 10}`)
        .then(resp => {
          commit('SET_DATA', resp.data)
          return resp
        })
    },
    async get({ commit }, { path, id }) {
      return await axios
        .get(`${path}/${id}/`)
        .then(resp => resp.data)
    },
    async create({ commit }, { path, fd }) {
      return await axios
        .post(`${path}/`, fd)
        .then(resp => {
          commit('PUSH_DATA', resp.data.data)
          return resp
        })
    },
    async update({ commit }, { path, id, fd }) {
      return await axios
        .post(`${path}/${id}/?_method=put`, fd)
        .then(resp => {
          commit('UPDATE_DATA', resp.data.data)
          return resp
        })
    },
    async delete({ commit }, { path, id }) {
      return await axios
        .delete(`${path}/${id}/`)
        .then(resp => {
          commit('DELETE_DATA', id)
          return resp
        })
    },
  },
}
