import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import dashboard from './routes/dashboard'
// import uiElements from './routes/ui-elements/cards'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'main' } },

    ...dashboard,
    // ...uiElements,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  const publicPages = ['auth-login']
  const authRequired = !publicPages.includes(to.name)
  const accessToken = localStorage.getItem('access_token')
  const isLogin = store.getters['auth/IS_LOGIN']
  if (!accessToken && authRequired) {
    next({ name: 'auth-login' })
  } else if (!authRequired && accessToken) {
    next({ name: 'dashboard' })
  } else {
    if (accessToken && !isLogin) {
      store.dispatch('auth/profile')
    }
    next()
  }
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
