import defineAbilityFor from '@/libs/acl/user_ability'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    isLogin: false,
    user: {},
  },

  mutations: {
    SET_USER_DATA(state, { data, isLogin }) {
      state.user = data?.data
      state.isLogin = isLogin
      defineAbilityFor(state.user)
    },
  },
  getters: {
    getAccessToken() {
      return localStorage.getItem('access_token')
    },
    ACCESS_TOKEN() {
      return localStorage.getItem('access_token')
    },
    GET_USER(state) {
      return state.user
    },
    IS_LOGIN(state) {
      return state.isLogin
    },
  },
  actions: {
    login({ commit }, fd) {
      return axios.post('auth/login/', fd).then(response => {
        commit('SET_USER_DATA', { data: response.data, isLogin: true })
        localStorage.setItem('access_token', response.data?.access_token)
        return response
      })
    },
    profile({ commit }) {
      return axios
        .get('me/profile')
        .then(response => {
          commit('SET_USER_DATA', { data: response.data, isLogin: true })
          return response
        })
    },
    logout({ commit }) {
      commit('SET_USER_DATA', { data: {}, isLogin: false })
      localStorage.removeItem('access_token')
    },
  },
}
