import $http from '@axios'

export default {
  // namespaced: true,
  state: {
    data: {
      results: [],
    },
  },
  mutations: {
    SET_DATA(state, data) {
      state.data = data
    },
    PUSH_DATA(state, data) {
      state.data.results.push(data)
    },
    UPDATE_DATA(state, data) {
      state.data = state.data.map(it => (it.id != id ? it : data))
    },
    DELETE_DATA(state, id) {
      state.data = state.data.filter(it => it.id != id)
    },
  },
  getters: {
    GET_DATA(data) {
      return data
    },
  },
  actions: {

    async getList({ commit }, path) {
      const response = await $http.get(path)
      commit('SET_ITEMS', response.data)
      return response
    },

    async getAll({ commit }, { path, page, limit }) {
      return await axios
        .get(`${path}?page=${page ?? 1}&limit=${limit ?? 10}`)
        .then(resp => {
          commit('SET_DATA', resp.data)
          return resp
        })
    },
    async getOne({ commit }, { path }) {
      return await $http
        .get(`${path}/${id}/`)
        .then(resp => resp.data)
    },
    async create({ commit }, { path, fd }) {
      return await $http
        .post(`${path}/`, fd)
        .then(resp => {
          commit('PUSH_DATA', resp.data)
          return resp
        })
    },
    async update({ commit }, { path, id, fd }) {
      return await $http
        .put(`${path}/${id}/`, fd)
        .then(resp => {
          commit('UPDATE_DATA', resp.data)
          return resp
        })
    },
    async delete({ commit }, { path, id }) {
      return await $http
        .delete(`${path}/${id}/`)
        .then(resp => {
          commit('DELETE_DATA', id)
          return resp
        })
    },
  },
}
