import Vue from 'vue'
import Vuex from 'vuex'

// Modules

import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

// New Add
import auth from './modules/auth'
import user from './modules/user'
import fetch from './modules/fetch'
import authorize from './modules/authorize'

import module from './module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth,
    user,

    module,
    fetch,
    authorize,
  },
  // strict: process.env.DEV,
})
