import axios from '@axios'

export default {
  namespaced: true,
  state: {
    roles: [],
    permissions: []
  },
  mutations: {
    SET_ROLES(state, data) {
      state.roles = data
    },
    SET_PERMISSIONS(state, data) {
      state.permissions = data
    },
  },
  getters: {
    GET_ROLES(state) {
      return state.roles
    },
    GET_PERMISSONS(state) {
      return state.permissions
    },
  },
  actions: {
    async getPermissions({ commit } ) {
      return await axios
          .get(`permissions`)
            .then(resp => {
              commit('SET_PERMISSIONS', resp.data)
              return resp
            })
    },
    async getRoles({ commit }) {
      return await axios
        .get(`roles`)
        .then(resp => {
            commit('SET_ROLES', resp.data)
            return resp
        })
    },
    
  },
}
